import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Resume from './components/Resume';
import Writing from './components/Writing';
import BlogPosts from './components/BlogPosts';
import YoutubeScripts from './components/YoutubeScripts';
import JournalArticle from './components/JournalArticle';
import EditingResearch from './components/EditingResearch';
import LDSInsights from './components/LDSInsights';
import './global.css';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/writing" element={<Writing />} />
          <Route path="/blog-posts" element={<BlogPosts />} />
          <Route path="/youtube-scripts" element={<YoutubeScripts />} />
          <Route path="/journal-article" element={<JournalArticle />} />
          <Route path="/editing-research" element={<EditingResearch />} />
          <Route path="/lds-insights" element={<LDSInsights />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
