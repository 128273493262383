import React from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  return (
    <header className="header">
      <nav className="navbar">
        <div className="logo">
          <Link to="/" className="logo-link">
            <h1>Stacey Limb</h1>
          </Link>
        </div>
        <ul className="nav-links">
          <li>
            <Link to="/resume">Resume</Link>
          </li>
          <li>
            <Link to="/writing">Writing</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
