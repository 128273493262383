import React from 'react';
import BackButton from './BackButton';
import './JournalArticle.css'; // Import CSS specific to the JournalArticle page

const JournalArticle: React.FC = () => {
  return (
    <section className="journal-article">
      <BackButton />
      <h2>Journal Article</h2>
      <p>
        This is an article I published in the Fall 2021 issue of Schwa, the BYU
        student linguistics journal. It relays my findings from a corpus study I
        conducted on the use of the subjunctive and indicative moods in the
        English language. The audience is any adult with an interest in
        linguistics, and the purpose is to educate the audience on one of the
        fun ways the English language is changing.
      </p>
      <div className="journal-content">
        <embed
          src="/schwa-article.pdf"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
    </section>
  );
};

export default JournalArticle;
