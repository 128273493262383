import React from 'react';
import BackButton from './BackButton';

const articleNames = [
  'God Knows Why We Pray',
  'No Trial Is Wasted',
  'Deciding Whom to Marry in the LDS Church',
  'It’s Not Bad; It’s Just Wrong',
];

const articleLinks = [
  'https://latterdaysaintinsights.byu.edu/en/god-knows-why-pray/',
  'https://latterdaysaintinsights.byu.edu/en/no-trial-is-wasted/',
  'https://latterdaysaintinsights.byu.edu/en/deciding-whom-to-marry-lds/',
  'https://latterdaysaintinsights.byu.edu/en/its-not-bad-its-just-wrong/',
];

const LDSInsights: React.FC = () => {
  return (
    <section className="lds-insights">
      <BackButton />
      <h2>Latter-day Saint Insights Articles</h2>
      <p>
        The following links are to short and sweet articles that I wrote for
        Latter-day Saint Insights. The purpose of this website is not to go
        in-depth on any topic but rather to give readers a quick inspirational
        lesson and lead them to further information from other sources. The
        target audience was mainly young adults, and we chose our own topics. We
        were instructed to always use at least one quote from a religious
        source, and we were encouraged to expound on the quote by using a
        personal experience or illustrative example where possible. This made
        the learning process more engaging by adding perspective and deeper
        meaning to the topics.
      </p>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {articleLinks.map((link, index) => (
          <li key={index} style={{ marginBottom: '1rem' }}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {articleNames[index]}
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default LDSInsights;
