import React from 'react';
import { Link } from 'react-router-dom';
import './Writing.css';

const Writing: React.FC = () => {
  return (
    <section className="writing">
      <h2>Writing Samples</h2>
      <div className="writing-grid">
        <Link to="/blog-posts" className="writing-item">
          <h3>Blog Posts</h3>
          <p>Explore my collection of informative and engaging blog posts.</p>
        </Link>
        <Link to="/youtube-scripts" className="writing-item">
          <h3>YouTube Scripts</h3>
          <p>Watch the educational YouTube videos that I wrote scripts for.</p>
        </Link>
        <Link to="/journal-article" className="writing-item">
          <h3>Journal Article</h3>
          <p>Learn from my published article in a prestigious journal.</p>
        </Link>
        <Link to="/editing-research" className="writing-item">
          <h3>Editing Research Article</h3>
          <p>Read about my research findings in the field of editing.</p>
        </Link>
        <Link to="/lds-insights" className="writing-item">
          <h3>Latter-day Saint Insights Articles</h3>
          <p>
            View my articles that provide insight into Latter-day Saint topics.
          </p>
        </Link>
      </div>
    </section>
  );
};

export default Writing;
