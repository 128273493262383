import React from 'react';
import './Resume.css'; // Import your CSS file for styling

const Resume: React.FC = () => {
  return (
    <section id="resume">
      <div className="container-fluid">
        <div className="row" data-aos="fade-up" data-aos-duration="1000">
          <div className="col-xs-1 col-md-2"></div>
          <div className="col-xs-10 col-md-8">
            <h1 className="resume-heading">Resume</h1>
            <div className="resume-content">
              <embed
                src="/Resume.pdf"
                type="application/pdf"
                width="100%"
                height="600px"
              />
            </div>
          </div>
          <div className="col-xs-1 col-md-2"></div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
