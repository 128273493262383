import React from 'react';
import BackButton from './BackButton';

const YoutubeScripts: React.FC = () => {
  const videoLinks = [
    'https://www.youtube.com/watch?v=tbcYMahgVyY',
    'https://www.youtube.com/watch?v=Z9qOHptj02A',
    'https://www.youtube.com/watch?v=HG7j2hW1Hbk',
    'https://www.youtube.com/watch?v=Ls6o8tSp3Eg',
    'https://www.youtube.com/watch?v=mGD7Bbg9zVk',
    'https://www.youtube.com/watch?v=lpvl9U4Urx0',
    'https://www.youtube.com/watch?v=oUzLR4ozkGs',
  ];

  return (
    <section className="youtube-scripts">
      <BackButton />
      <h2>YouTube Scripts</h2>
      <p>
        I researched business and finance topics to write educational video
        scripts for Coby Hunter's YouTube Channel. These scripts inform adults
        about topics like spending money wisely and creating successful
        businesses. For the style of Coby's channel, our goal was to make the
        learning fun; perhaps the video is clicked on because someone searched
        "how to ___" or "why did ____", but a lot of time people spend on
        YouTube is relaxation time, and viewers want content to entertain them.
        One script I wrote for him – an informational video about what made the
        Stanley Quencher Cup so successful – is one of the most viewed videos on
        his page, with over 28k views.
      </p>
      <p>
        To best educate viewers, we typically started the video with a hook and
        a quick outline, which often included a list. Then, the main content was
        often a deeper explanation of each point in the list. When possible, I
        included graphs or similar images along with my written script for Coby
        to display on the screen while reading the script. Finally, the
        conclusion summarized the educated opinion we'd formed about the topic.
      </p>
      <p>
        Many factors influence what makes a YouTube video earn more views and be
        successful: how many YouTube videos already cover a topic, what the
        video title is, how well the video is edited, when it is posted, and how
        well the script hooks viewers in by the introduction and holds their
        attention. But I will acknowledge that I was only in charge of the last
        part, and he always had the final say in the script and did all of the
        video editing.
      </p>
      <div className="video-thumbnails">
        {videoLinks.map((link, index) => (
          <a
            key={index}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="thumbnail-link"
          >
            <img
              src={`https://img.youtube.com/vi/${link.split('v=')[1]}/0.jpg`}
              alt={`Video ${index + 1}`}
              className="thumbnail"
            />
          </a>
        ))}
      </div>
    </section>
  );
};

export default YoutubeScripts;
