import React from 'react';

const Home: React.FC = () => {
  return (
    <section className="home">
      <h1>Welcome to My Portfolio</h1>
      <p>
        I graduated from BYU-Provo in April of 2023 with a major in editing and
        publishing and a minor in professional writing and rhetoric. I enjoy
        playing frisbee, rock climbing, playing piano, cuddling cats, spending
        time with family, and just being outdoors—but only when it's warm! I
        also enjoy reading, writing, and working with grammar. Helping create
        content that educates and helps readers is my passion! On this site, you
        will find samples of my writing, including educational material in the
        form of articles, blogs, and videos.
      </p>
    </section>
  );
};

export default Home;
