import React from 'react';
import BackButton from './BackButton';

const EditingResearch: React.FC = () => {
  return (
    <section className="editing-research">
      <BackButton />
      <h2>Editing Research</h2>
      <p>
        This is an article I wrote for the website Editing Research. I explained
        an eye-tracking study, summarized the salient results, provided
        implications, and encouraged readers to read the full article. My
        article helps readers understand how to better format wordy websites
        based on the data it presents about how humans tend to read. This
        article is short because the goal of Editing Research is to present
        findings simply but encourage readers to go to original sources if they
        find something they're interested in.
      </p>
      <a
        href="https://editingresearch.byu.edu/2023/04/07/best-font-size-and-line-spacing/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Read Editing Research Article
      </a>
    </section>
  );
};

export default EditingResearch;
