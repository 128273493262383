import React from 'react';
import BackButton from './BackButton';

const BlogPosts: React.FC = () => {
  return (
    <section className="blog-posts">
      <BackButton />
      <h2>Blog Posts</h2>
      <p>
        The following link will take you to the blog I ran during the summer and
        fall of 2022. The content of each blog post was based heavily on videos
        the company's marriage counselor had produced over prior years. To
        present the informative content as clearly as possible, I extensively
        revised the scripts from the videos and turned them into blog posts by
        changing the tone, splitting or combining scripts for appropriate
        length, reorganizing paragraphs, cutting parts out, and adding parts in,
        all with the approval of the counselor. All of the videos are published
        under the counselor's name, but I created all the posts between May and
        October of 2022.
      </p>
      <p>
        These scripts educated adults on how to be better spouses, deal with
        mental health challenges, and mainly, how to better communicate with
        their spouses. Some difficult topics included how to know whom to marry,
        when divorce is necessary, and what to do if your spouse isn't making
        changes you need them to make. To most effectively teach these topics,
        we used analogies, statistics, and stories that the counselor had
        gathered over his years of counseling and raising his own family. Having
        this variety made the blog posts a much more engaging learning
        experience.
      </p>
      <a
        href="https://communicatinglove.com/blog/"
        target="_blank"
        rel="noopener noreferrer"
      >
        View Blog Posts
      </a>
    </section>
  );
};

export default BlogPosts;
